import Rest from '@/services/Rest';

/**
 * @typedef {ReportAnswerService}
 */
export default class ReportAnswerService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/report-answer'
}
