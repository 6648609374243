<template>
<div class="col-12 px-0">
    <div class="container-fluid p-dash-cliente-master pb-2 pt-3">
    <div class="col-12">
        <div class="col-12 pb-2">
            <div class="row">
                <div class="col-auto align-self-center text-right px-0">
                    <b-link class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta" :to="{name: 'Dashboard'}"><i class="fas fa-arrow-left"></i></b-link>
                </div>
                <div class="col-10 align-self-center">
                    <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">Chamados</h3>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb m-0 p-0">
                                <li class="breadcrumb-item"><router-link :to="{name: 'Dashboard'}">dashboard</router-link> / <router-link :to="{name: 'Report'}">assistência técnica</router-link></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-11 col-md-12 col-lg-12 mx-auto p-0">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-8 order-2 order-md-1 order-lg-1">
                            <div class="form-group d-flex">
                                <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                                <button type="submit" class="btn btn-info" @click="fetchReports">Buscar</button>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 order-1 order-md-2 order-lg-2">
                            <div class="form-group d-flex">
                                <b-link :to="{name: 'ReportNew'}" class=" ml-auto">
                                    <button type="submit" class="btn btn-dark"><i class="far fa-file-alt mr-2"></i>Novo chamado</button>
                                </b-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-none d-lg-block pb-3 px-0">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6 col-md-auto col-lg-auto pr-1">
                                <select class="custom-select mr-sm-2 campo-filter-1" v-model="filter.open_like" @change="fetchReports">
                                    <option :value="null">Tipo</option>
                                    <option value="LIQUIDATOR">Síndico</option>
                                    <option value="CLIENT">Cliente</option>
                                </select>
                            </div>
                            <div class="col-6 col-md-auto col-lg-auto pr-1 pl-1">
                                <select class="custom-select mr-sm-2 campo-filter-2" v-model="filter.status" @change="fetchReports">
                                    <option :value="null">Status</option>
                                    <option value="PENDING">Novo</option>
                                    <option value="IN_PROGRESS">Em andamento</option>
                                    <option value="FINISHED">Resolvido</option>
                                </select>
                            </div>
                            <div class="col-6 col-md-auto col-lg-auto pr-1 pl-1">
                                <b-form-select class="custom-select mr-sm-2 campo-filter-3" v-model="filter.technical_id" :options="technical" @change="fetchReports"></b-form-select>
                            </div>
                            <div class="col-6 col-md-auto col-lg-auto pr-1 pl-1">
                                <b-form-select class="custom-select mr-sm-2 campo-filter-4" v-model="filter.precedent_id" :options="precedents" @change="fetchReports"></b-form-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <div class="card-list-legenda">
                    <div class="card-body-list-legend d-none d-lg-block">
                        <div class="col-12 p-0 align-self-center">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                        <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                    </div>
                                    <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                        <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                    </div>
                                    <div class="col-7 col-md-6 col-lg-9 p-0 order-3 align-self-center">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                    <p class="text-truncate title-legend-list">Imóvel</p>
                                                </div>
                                                <div class="col-12 col-md-12 col-lg-2 align-self-center text-muted pad-content-list">
                                                    <p class="text-truncate title-legend-list">Nome</p>
                                                </div>
                                                <div class="col-auto col-md-auto col-lg-1 align-self-center pad-content-list">
                                                    <p class="text-truncate title-legend-list">N˚ torre</p>
                                                </div>
                                                <div class="col-8 col-md-4 col-lg-2 align-self-center text-muted pad-content-list">
                                                    <p class="text-truncate title-legend-list">N˚ apto</p>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4 align-self-center pad-content-list">
                                                    <p class="text-truncate title-legend-list">Assunto</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-list" v-for="item in reports" :key="item.id">
                    <div class="card-body-list">
                        <div class="col-12 p-0 align-self-center">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                        <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                    </div>
                                    <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                        <img src="~@/assets/img/users/widget-table-pic1.jpg" alt="user" class="rounded-circle card-body-list-img" />
                                    </div>
                                    <div class="col-7 col-md-8 col-lg-9 p-0 order-3 align-self-center">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                    <h6 class="text-truncate cod"><span>CÓD. TICKET:</span> {{ item.id }}</h6>
                                                    <h6 class="text-truncate card-body-list-title d-none d-lg-block">{{ item.enterprise.name }}</h6>
                                                    <div class="progress barra-progress">
                                                        <div class="progress-bar bg-primary" role="progressbar" style="width: 30%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-12 col-lg-2 align-self-center pad-content-list">
                                                    <h6 class="text-truncate card-body-list-title">{{ item.client.first_name }} {{ item.client.last_name }}</h6>
                                                </div>
                                                <div class="col-auto col-md-auto col-lg-1 align-self-center pad-content-list">
                                                    <h6 class="text-truncate torre">{{ item.tower }}</h6>
                                                </div>
                                                <div class="col-8 col-md-4 col-lg-2 align-self-center pad-content-list">
                                                    <h6 class="text-truncate apto">{{ getFloors(item.floors) }}</h6>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4 align-self-center text-muted pad-content-list">
                                                    <h6 class="text-truncate empreendi">{{ item.subject }}</h6>
                                                    <h6 class="text-truncate cod-tec">Téc.: <span v-if="item.technical">{{ item.technical.first_name }} {{ item.technical.last_name }}</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 col-md-auto col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                        <a class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta" data-bs-toggle="modal" data-bs-target="#right-modal" href="#right-modal" @click="id = item.id"><i class="fas fa-eye"></i></a>
                                        <a class="btn btn-orange rounded-circle btn-circle font-20 card-body-list-cta" data-bs-toggle="modal" data-bs-target="#right-modal2" href="#right-modal2" @click="id = item.id"><i class="fas fa-comments"></i></a>
                                        <router-link class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta" :to="{name: 'ReportEdit', params:{ id: item.id }}">+</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- No reports -->
                <div class="row" v-if="reports.length == 0">
                    <div class="col-12">
                        <div class="text-center">
                            Sem chamados
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <BasePagination
                    v-if="reports.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage" />
            </div>
        </div>
    </div>
</div>
<ViewReport :id="id" />
<ViewMsg :id="id" />
</div>
</template>
<script>
import ReportService from "@/services/resources/ReportService";
import ConfigService from "@/services/resources/ConfigService";
import TechnicalService from "@/services/resources/TechnicalService";
import ViewReport from '@/components/Reports/ViewReport';
import ViewMsg from '@/components/Reports/ViewMsg';

const serviceConfig = ConfigService.build();
const serviceTechnical = TechnicalService.build();
const service = ReportService.build();

export default {
    components:{
        ViewReport,
        ViewMsg
    },
    data(){
        return{
            reports: [],
            pagination: {
                totalPages: 1,
                currentPage: 1,
                perPage: 10,
                itens: 0
            },
            filter:{
                open_like: null,
                status: null,
                technical_id: null,
                precedent_id: null
            },
            search: null,
            precedents: [
                { value: null, text: 'Selecione' }
            ],
            technical: [
                { value: null, text: 'Selecione' }
            ],
            id: null
        }
    },
    methods:{
        getFloors(floors){
            return floors.map(elem => {
                return elem.unity.number;
            }).join(',');
        },
        toPage(page) {
            this.pagination.currentPage = page;
            this.fetchReports();
        },
        perPage(qtd) {
            this.pagination.currentPage = 1;
            this.pagination.perPage = qtd;

            this.fetchReports();
        },
        async fetchTechnical(){
            return serviceTechnical.search().then();
        },
        async fetchConfig(type){
            let data = {
            type: type
            }
            return serviceConfig.search(data).then();
        },
        fetchReports(){

            let data = {
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
            }

            if(this.filter.open_like){
                data.open_like = this.filter.open_like;
            }

            if(this.filter.status){
                data.status = this.filter.status;
            }

            if(this.filter.technical_id){
                data.technical_id = this.filter.technical_id;
            }

            if(this.filter.precedent_id){
                data.precedent_id = this.filter.precedent_id;
            }

            if(this.search){
                data.search = this.search;
            }
        
            service
            .search(data)
            .then(resp => {
                this.reports = resp.data;
                this.pagination.totalPages = resp.last_page;
                this.pagination.itens = resp.total;
            })
            .catch(err => {
                console.log(err)
            })
        },
    },
    async mounted() {
        this.fetchReports()

        var resp = await this.fetchTechnical();
        this.technical = this.technical.concat(resp.map(elem => {
            return {
                value: elem.id,
                text: elem.first_name + ' ' + elem.last_name
            }
        }))

        var resp = await this.fetchConfig('PRECEDENTS');
        this.precedents = this.precedents.concat(resp.map(elem => {
            return {
                value: elem.id,
                text: elem.name
            }
        }))


    },
}
</script>