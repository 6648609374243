<template>
    <!-- .modal -->
<div id="right-modal2" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-right">
        <div class="modal-content">
            <div class="modal-header border-0">
                <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true"><i class="ti-close font-20"></i></button>
            </div>
            <div class="modal-body">
                <form action="" class="px-2 needs-validation" novalidate="">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-12 pt-4">
                                <img v-if="report.enterprise.image" :src="report.enterprise.image" alt="Logo" style="max-height: 50px;">
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Aberto em</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.created_at | date}}</h3>
                            </div>
                            <div class="col-12 pt-4">
                                <p class="page-txt-view-chamado">Assunto</p>
                                <h3 class="page-title-view-chamado-2 font-weight-medium mb-1">{{ report.subject }}</h3>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Qual status do chamado?</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.status == 'PENDING' ? 'Novo' : (report.status == 'IN_PROGRESS' ? 'Em andamento' : 'Finalizado') }}</h3>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Não conformidades</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.conformity.name }}</h3>
                            </div>
                            <div class="col-12 col-md-4 col-lg-4 pt-4">
                                <p class="page-txt-view-chamado">Procedentes</p>
                                <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ report.precedent_id ? 'Sim' : 'Não' }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="form-body">
                        <div class="form-group pt-3">
                            <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">interações</h3>
                        </div>
                        <div class="form-group">
                            <label>Data da visita técnica </label>
                            <div><b>{{ report.date | date }}</b></div>
                        </div>
                        <h3>Respostas</h3>
                        <div class="form-group" v-for="item in report.answers" :key="item.id">
                            <label class="pl-0">{{ item.created_at | date }}</label>
                            <div class="text-content-exibir"><b>Sr(a).</b> {{ item.user.first_name }} {{ item.user.last_name }}</div>
                            <div>{{ item.answer }}</div>
                            <div class="py-1"><i class="fas fa-image"></i> Imagens: <a :href="item.image" target="_blank" class="a-link-1-img" title="Imagem" v-if="item.image">01</a></div>
                        </div>
                        <form novalidate @submit.prevent="save" v-if="report.status != 'FINISHED'">
                            <div class="form-body">
                                <div class="row">
                                    <div class="col-12 col-md-12 col-lg-12 pt-3">
                                        <div class="form-group">
                                            <label>Responder </label>
                                            <textarea class="form-control" rows="6" placeholder="Mensagem" v-model="answer" v-validate="'required'" name="answer" :class="{'is-invalid': errors.has('answer')}"></textarea>
                                            <small id="textHelp" class="form-text text-muted">Informe detalhes sobre o chamado.</small>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-12 col-lg-12">
                                        <vue-upload-multiple-image
                                            @upload-success="uploadImageSuccess"
                                            @before-remove="beforeRemove"
                                            @edit-image="editImage"
                                            :data-images="images"
                                            idUpload="myIdUpload"
                                            editUpload="myIdEdit"
                                            dragText="120px por 120px"
                                            browseText="Procurar imagens"
                                            :showPrimary="false"
                                            :showEdit="false"
                                            :multilple="false"
                                            :maxImage="1">
                                        </vue-upload-multiple-image>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions">
                                <div class="text-center py-1">
                                    <button type="submit" class="btn btn-danger btn-relatar-problema">Responder <i class="fas fa-comments font-18 align-middle ml-1"></i></button>
                                </div>
                            </div>
                        </form>
                        <div class="form-actions">
                            <div class="text-center py-1">
                                <button type="button" v-if="report.status != 'FINISHED'" @click="update" class="btn btn-success concluir-chamado"><i class="far fa-thumbs-up font-18 align-middle ml-1"></i> Encerrar atendimento</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ReportService from "@/services/resources/ReportService";
import ReportAnswerService from "@/services/resources/ReportAnswerService";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import moment from 'moment';

const service = ReportService.build();
const serviceAnswer = ReportAnswerService.build();

export default {
    components:{
        VueUploadMultipleImage
    },
    props:{
        id:{
            type: Number
        }
    },
    data(){
        return{
            report: {
                user:{
                    first_name: null,
                    last_name: null
                },
                open_like: null,
                created_at: null,
                subject: null,
                enterprise:{
                    name: null
                },
                technical:{
                    first_name: null,
                    last_name: null
                },
                conformity:{
                    name: null
                },
                images: []
            },
            answer: null,
            images: []
        }
    },
    filters:{
      date(date){
          return moment(date).format('DD/MM/YYYY H:MM');
      }
    },
    watch:{
        id(){
            this.fetchReport();
        }
    },
    methods:{
        uploadImageSuccess(formData, index, fileList) {
            this.images = fileList;
        },
        beforeRemove(index, done, fileList) {
            var r = confirm("Remover imagem?");
            if (r == true) {
                this.images.splice(index, 1);
                done();
            }
        },
        editImage(formData, index, fileList) {
            this.images = fileList;
        },
        getOpenLike(open_like){

            switch(open_like){
                case 'LIQUIDATOR':
                    return 'Síndico';
                case 'TECHNICAL':
                    return 'Técnico';
                case 'LESSE':
                    return 'Locatário';
                case 'CLIENT':  
                    return 'Cliente';
                default:
                    return '';
            }

        },
        fetchReport(){

            let data = {
                id: this.id
            }

            service
            .read(data)
            .then(resp => {
                this.report = resp;
            })
            .catch(err => {
                console.log(err)
            })
        },
        save(){

            this.$validator.validateAll().then((result) => {
                if (result) {

                    let data = {
                        answer: this.answer,
                        image: this.images[0],
                        report_id: this.id
                    };

                    serviceAnswer
                    .create(data)
                    .then(resp => {
                        this.$bvToast.toast('Sua responta foi adicionado com sucesso!', {
                            title: 'Resposta adicionada',
                            autoHideDelay: 5000,
                            type: 'success'
                        });
                        this.answer = null;
                        this.$validator.reset();
                        this.images = [];

                        this.fetchReport();
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
            });
        },
        update(){

            let data = {
                id: this.id,
                status: 'FINISHED'
            }

            service
            .update(data)
            .then(resp => {
                this.$bvToast.toast('Chamado encerrado!', {
                    title: 'Chamado',
                    autoHideDelay: 5000,
                    type: 'success'
                });
            })
            .catch(err => {
                console.log(err)
            })
        }
    }
}
</script>